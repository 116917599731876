import './index.scss'
import Loader from "react-loaders";
import {useEffect, useState} from "react";
import AnimatedLetters from "../AnimatedLetters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHtml5, faJava, faJsSquare, faPython, faReact, faSwift} from "@fortawesome/free-brands-svg-icons";

const Skills = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    return (
        <>
            <div className="container skills-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['T', 'e', 'c', 'h', 'n', 'i', 'c', 'a', 'l', '', 'S', 'k', 'i', 'l', 'l', 's']}
                            idx={10}
                        />
                    </h1>
                    <p>
                        Being a perosn that is constantly learning, I have a wide range of
                        technical skills that I have acquired over the years. I am always
                        looking to learn new skills and technologies to further my knowledge
                        and experience.
                    </p>
                    <p>
                        {' '}
                        I'm currently working on a few projects that have me working with
                        Next.js, PostgreSQL, Prisma, Python, Java, and C. I am also learning
                        more about how to properly create a Full Stack application. I am also learning
                        more about Machine Learning algorithms and how to develop Artificial Intelligence
                        code.
                    </p>
                </div>


                <div className="stage-cube-cont">
                    <div className="cubespinner">
                        <div className="face1">
                            <FontAwesomeIcon icon={faJava} color="#DD0031" />
                        </div>
                        <div className="face2">
                            <FontAwesomeIcon icon={faHtml5} color="#F06529" />
                        </div>
                        <div className="face3">
                            <FontAwesomeIcon icon={faSwift} color="#28A4D9" />
                        </div>
                        <div className="face4">
                            <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                        </div>
                        <div className="face5">
                            <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
                        </div>
                        <div className="face6">
                            <FontAwesomeIcon icon={faPython} color="#EC4D28" />
                        </div>
                    </div>
                </div>

            </div>
            <Loader type="pacman" />
        </>
    );
}

export default Skills