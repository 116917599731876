
import { Link, NavLink } from 'react-router-dom'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faEnvelope, faSuitcase, faEye,faChess} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub, faYoutube, faSkype, faInstagram, faWhatsapp,faPage4 } from '@fortawesome/free-brands-svg-icons'
import LogoS from '../../assets/images/BigTempLogo.png'
import LogoSubtitle from '../../assets/images/BigNameWhite.png'
import Pdf from '../KaamilResume.pdf';

const Sidebar = () => {
    return (
        <div className='nav-bar'>
            <Link className='logo' to='/'>
                <img src={LogoS} alt="Logo"/>
                <img className='sub-logo' src={LogoSubtitle} alt="slobodan"/>
            </Link>
            <nav>
                <NavLink exact="true" to="/" activeClassName="active" className="home-link">
                    <FontAwesomeIcon icon={faHome} color="#4d4d4e"/>
                </NavLink>
                <NavLink to="/about" className="about-link" activeClassName="active">
                    <FontAwesomeIcon icon={faUser} color="#4d4d4e"/>
                </NavLink>
                <NavLink
                    exact="true"
                    to="/skills"
                    className="skills-link"
                    activeClassName="active">
                    <FontAwesomeIcon icon={faChess} color="#4d4d4e"/>
                </NavLink>
                <NavLink
                    exact="true"
                    to="/experience"
                    className="experience-link"
                    activeClassName="active">
                    <FontAwesomeIcon icon={faSuitcase} color="#4d4d4e"/>
                </NavLink>
                <NavLink
                    exact="true"
                    to="/portfolio"
                    className="portfolio-link"
                    activeClassName="active">
                    <FontAwesomeIcon icon={faEye} color="#4d4d4e"/>
                </NavLink>
                <NavLink
                    exact="true"
                    to="/contact"
                    className="contact-link"
                    activeClassName="active">
                    <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e"/>
                </NavLink>
                <li class="resume-link">
                    <a target="_blank"
                       rel="nonreferrer"
                       href={Pdf}>
                        <FontAwesomeIcon icon={faPage4} color="#4d4d4e" className="resume-link"/>
                    </a>
                </li>
            </nav>
            <ul>
                <li>
                    <a target="_blank"
                       rel="nonreferrer"
                       href="https://www.linkedin.com/in/kaamil-thobani-9a7237210/">
                        <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e"/>
                    </a>
                </li>
                <li>
                    <a target="_blank"
                       rel="nonreferrer"
                       href="https://github.com/kaamil2">
                        <FontAwesomeIcon icon={faGithub} color="#4d4d4e"/>
                    </a>
                </li>

                <li>
                    <a target="_blank"
                       rel="nonreferrer"
                        href="https://www.instagram.com/kaamilthobani/">
                        <FontAwesomeIcon icon={faInstagram} color="#4d4d4e"/>
                    </a>
                </li>

                <li>
                    <a target="_blank"
                       rel="nonreferrer"
                        href="https://wa.me/+14699007473">
                        <FontAwesomeIcon icon={faWhatsapp} color="#4d4d4e"/>
                    </a>
                </li>
            </ul>
        </div>


    )

}

export default Sidebar