
import './index.scss'
import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { useTransition, animated } from '@react-spring/web'


const slides = [

    `https://i.imgur.com/ZcGJOu3.jpg`,
    `https://i.imgur.com/81tK8J9.jpg`

]

const About = () => {

    const [index, set] = useState(0)
    const transitions = useTransition(index,{
        key: index,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 3000 },
        onRest: (_a, _b, item) => {
            if (index === item) {
                set(state => (state + 1) % slides.length)
            }
        },
        exitBeforeEnter: true,
    })


    const [letterClass, setLetterClass] = useState('text-animate')
   useEffect(() => {
         setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    return (
        <>
            <div className='container about-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['A', 'b', 'o', 'u', 't', '', 'M', 'e']}
                            idx={5}
                        />
                    </h1>
                    <p>
                       I am currently pursuing a Bachelor of Science in Computer Science and
                        minor in Buisness Analytics. I am passionate about artificial
                        Intelligence and Machine Learning. I am also interested in
                        Web Development and Software Engineering. I am currently
                        looking for a 6 month co-op starting in January 2024.
                    </p>
                    <br/>
                    <br/>

                    <h2>
                        But Who Am I? Well I'm born and raised from Dallas,Texas so being born a dallas sports fan
                        is a must. I'm a huge fan of the Dallas Cowboys and Dallas Mavericks. I tend to be a very active
                        person I love going out and playing pickup basketball with some friends. I consider myself to
                        be a huge foodie and love trying new foods. I also love to travel and explore new places. I'm a
                        huge fan of the outdoors and love to go hiking and camping. I'm also a huge fan of music and love
                        Artsits like J.Cole, Tyler the Creator, Frank Ocean, Brent Faiyaz and many more.

                    </h2>
                    <h2>
                        I'm naturally curious and perpetually working on improving my skills one design problem at a time.
                        I have a passion for creating challenging, intuitive and beautiful products. I'm always looking to
                        learn new things and expand my knowledge. I'm always looking for new opportunities to learn and grow
                        as a person. I'm always looking to meet new people and make new connections.
                    </h2>
                </div>
                <div className="flex fill center">
                    {transitions((style, index) => (
                        <animated.div
                            className='bg'
                            style={{
                                ...style,
                                backgroundImage: `url(${slides[index]})`,
                            }}
                        />
                    ))}
                </div>
                <Loader type={"pacman"} />
            </div>
        </>

);
}

export default About