import './index.scss'
import React from 'react'
import AnimatedLetters from '../AnimatedLetters'
import Loader from 'react-loaders'
import { useState, useEffect } from 'react'
import experienceData from '../../data/experience.json';


const Experience = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['E', 'x', 'p', 'e', 'r', 'i', 'e', 'n', 'c', 'e']

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => clearTimeout(timer);

    });


    const renderExperience = (experience) => {
        return (

            <div className="experience-container">
                {
                    experience.map((exp, idx) => {
                        return (
                            <div className="experience-box" key={idx}>
                                <img
                                    src={exp.cover}
                                    className="experience-image"
                                    alt="experience"/>
                                <div className="experience-content">
                                    <p className="experience-title">{exp.title}</p>
                                    <h4 className="experience-description">{exp.description}</h4>
                                    <button
                                        className="experience-btn"
                                        onClick={() => window.open(exp.url)}>
                                        View
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    return (

        <div className="container experience-page">
            <h1 className="page-title">
                <AnimatedLetters
                    letterClass={letterClass}
                    strArray={nameArray}
                    idx={15}/>
            </h1><div>{renderExperience(experienceData.experience)}</div>
            <Loader type="pacman"/>
        </div>

    );


}

export default Experience;