import './index.scss'
import Loader from 'react-loaders'
import { useState, useEffect, useRef } from 'react'
import AnimatedLetters from '../AnimatedLetters'
import emailjs from '@emailjs/browser'




const Contact = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const form = useRef()



    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    const sendEmail = (e) => {

       e.preventDefault();
      emailjs
            .sendForm(
                'service_yrgxxpw',
                'template_inza5z1',
                form.current,
                'fzJIX8mrXKC1lVGSa')
        .then (
            () => {

            alert('Email sent successfully!')
                window.location.reload(false)

            },
            () => {
                alert('Email failed to send')
            }
        )

    }

    return (
        <>
            <div className='container contact-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={['C','o','n','t','a','c','t',' ','M','e']}
                        idx={15} />
                </h1>
                <p>
                    I am a student at Northeastern University. I am currently
                    pursuing a Bachelor of Science in Computer Science and
                    minor in Buisness Analytics. I am passionate about artificial
                    Intelligence and Machine Learning. I am also interested in
                    Web Development and Software Engineering. I am currently
                    looking for a 6 month co-op starting in January 2024.
                </p>

                return (
                <div className='contact-form'>
                <form ref={form} onSubmit={sendEmail}>
                    <ul>
                        <li className="half">
                            <input type="text" name={"name"} placeholder="Name" required />
                        </li>
                        <li className="half">
                            <input type="email" name={"email"} placeholder="Email" required />
                        </li>
                        <li>
                            <input type="text" name={"subject"} placeholder="Subject" required />
                        </li>
                        <li>
                                <textarea name={"message"} placeholder="Message" required
                                ></textarea>
                        </li>
                        <li>
                            <input type="submit" value="Send Message" className='flat-button'/>
                        </li>

                    </ul>
                </form>
                </div>
                );


               {/* <div className='contact-form'>
                    <form ref{form} onSumbit={sendEmail}>
                        <ul>
                            <li className="half">
                                <input type="text" name={"name"} placeholder="Name" required />
                            </li>
                            <li className="half">
                                <input type="email" name={"email"} placeholder="Email" required />
                            </li>
                            <li>
                                <input type="text" name={"subject"} placeholder="Subject" required />
                            </li>
                            <li>
                                <textarea name={"message"} placeholder="Message" required
                                ></textarea>
                            </li>
                            <li>
                                <input type="submit" value="Send Message" className='flat-button'/>
                            </li>

                        </ul>
                    </form>
                </div>*/}
            </div>
            </div>
            <Loader type='pacman' />

        </>

    )

}

export default Contact

