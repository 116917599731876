import LogoTitle from '../../assets/images/BigKBlack.png'
import { Link } from 'react-router-dom'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useState, useEffect } from 'react'
import Logo from './Logo'
import Loader from 'react-loaders'
import Logotemp from '../../assets/images/BigTempLogo.png'


const Home = () => {
    const [letterClass,setLetterClass] = useState('text-animate')
    const nameArray = [ 'a', 'a', 'm', 'i', 'l',
        ' ', 'T', 'h', 'o', 'b', 'a', 'n', 'i']
    const jobArray =
        ['S','t','u','d','e','n','t',' ','a','t',
            '','N','o','r','t','h','e','a','s','t','e','r','n',
            '','U','n','i','v','e','r','s','i','t','y']


     useEffect(() => {
         setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000)
    }, [])


    return (
        <>
            <div className="container home-page">
                <div className="text-zone">
                    <h1>
                        <span className={letterClass}>H</span>
                        <span className={`${letterClass} _12`}>i,</span>
                        {' '}
                        <span className={`${letterClass} _13`}>I</span>
                        <span className={`${letterClass} _14`}>'m</span>
                        <br/>
                        <img
                            src={LogoTitle}
                            alt="JavaScript Developer Name, Web Developer Name"
                        />
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={nameArray}
                            idx={22}
                        />
                        <br />
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={jobArray}
                            idx={40}
                        />
                    </h1>
                    <h2>Computer Science Major / Artificial Intelligence Concentrate </h2>
                    <Link to="/contact" className="flat-button">
                        CONTACT ME
                    </Link>
                </div>
                {/*<img src={Logotemp} alt="Logo" className="logo" />*/}
               {/* <Logo /> uncomment when you have proper svg*/}
            </div>
            <Loader type="pacman" />
        </>
    )
}

export default Home